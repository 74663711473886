/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useMutation, UseQueryResult, useQuery } from 'react-query';
import axios from '@utils/axios';
import { SentMailing } from '@api/database/models/sentMailing';

interface RequestData {
  slug?: string | null;
  ASIN?: string | null;
  promoCode?: string | null;
  subject?: string | null;
  mailingListId?: string | null;
  options?: {
    showVariations?: boolean;
    showVariationsThreshold?: string;
    emailBeginning?: string;
    additionalImages?: string[];
    mainImage?: string;
    additionalPostText?: string;
    scheduleAt?: Date | null;
    otherMailingType?: string | null;
    shouldBlogLinksBePageLink?: boolean;
  };
}

export function useSendMailing() {
  const mutationFn = async ({
    ASIN,
    slug,
    promoCode,
    mailingListId,
    subject,
    options
  }: RequestData): Promise<{
    data: {
      hasSentMailing?: boolean;
      thumbnailUrl?: string;
      error?: string;
    };
  }> => {
    return axios.post(`/api/dashboard/send-mailing`, {
      ASIN,
      slug,
      promoCode,
      subject,
      mailingListId,
      options
    });
  };

  return useMutation(mutationFn, {
    retry: false
  });
}

const queryMailingHistory = async ({
  ASIN,
  slug,
  promoCode
}: RequestData): Promise<SentMailing[]> => {
  const result = await axios.post<{
    data: SentMailing[];
  }>(`/api/dashboard/mailing-list-history`, {
    slug,
    ASIN,
    promoCode
  });
  return result.data;
};

export function useGetMailingHistoryEnabled(
  req: RequestData
): UseQueryResult<SentMailing[]> {
  return useQuery<SentMailing[], Error>(
    `query-mailing-history-${req.ASIN}-${req.slug}-${req.promoCode}}`,
    () => queryMailingHistory(req),
    {
      onError: () => {
        console.error('Something went wrong while fetching mailing history');
      }
    }
  );
}
